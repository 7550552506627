





























































































































































































































































































































.upload-demo {
    .el-upload {
        height: 40px !important;
        border: 0;
    }
}
